<template>
  <div class="about">
    <div>
      <el-select
          style="margin-right: 10px"
          size="mini"
          v-model="referrer_member_id"
          filterable
          remote
          clearable
          reserve-keyword
          placeholder="选择员工查询"
          :remote-method="onhigher"
          :loading="loading">
        <el-option
            v-for="item in higherArr"
            :key="item.id"
            :label="item.nickname+'-'+item.mobile_phone"
            :value="item.id">
        </el-option>
      </el-select>
      <el-date-picker
          style="margin-right:10px"
          size="mini"
          v-model="create_time"
          type="daterange"
          value-format="YYYY-MM-DD"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
      </el-date-picker>
      <el-select
          clearable
          placeholder="选择性别"
          style="margin-right: 10px;width: 100px"
          size="mini"
          v-model="idcard_sex">
        <el-option
            v-for="item in sexArr"
            :key="item.id"
            :label="item.id"
            :value="item.id">
        </el-option>
      </el-select>
      <el-input style="width: 200px;margin: 0 10px 0 0" size="mini"  v-model="keywords"></el-input>
      <el-button size="mini" @click="search()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
    </div>
    <el-table  height="calc(100vh - 255px)" :data="tableData" style="width: 100%;">
      <el-table-column  align="center"
                        prop="id"
                        width="75" label="ID" >
      </el-table-column>
      <el-table-column width="100" prop="name" label="头像" >
        <template #default="scope">
          <div style="height: 100%;display: flex;align-items:center;padding: 5px 0">
            <el-image
                v-if="scope.row.profile_url"
                style="width: 50px; height: 50px"
                :src="scope.row.profile_url"
                :preview-src-list="[scope.row.profile_url]">
            </el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="nickname" label="用户名称" >
      </el-table-column>
      <el-table-column prop="mobile_phone" label="手机号" >
      </el-table-column>
      <el-table-column width="80" prop="is_check" label="是否认证">
        <template #default="scope">
          <el-tag size="mini" v-if="scope.row.is_check">已认证</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" >
      </el-table-column>
      <el-table-column prop="address" label="操作" width="110">
        <template #header>
          <el-button size="mini" @click="add()" type="primary" style="margin:10px 0;">新增用户</el-button>
        </template>
        <template #default="scope">
          <div style="margin: 5px 0">
            <el-button-group>
              <el-button size="mini" type="primary"  @click="upData(scope.row)">查看详情</el-button>
            </el-button-group>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 20px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 30, 40,50]"
          :page-size="limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
    <el-drawer
        title="详情"
        v-model="drawer"
        size="60%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <el-descriptions :column="4" border :title="ruleForm.nickname">
        <el-descriptions-item label="头像">
          <div style="height: 100%;display: flex;align-items:center;padding: 5px 0">
            <el-image
                v-if="ruleForm.profile_url"
                style="width: 60px; height: 60px"
                :src="ruleForm.profile_url"
                :preview-src-list="[ruleForm.profile_url]">
            </el-image>
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="昵称:">{{ruleForm.nickname}}</el-descriptions-item>
        <el-descriptions-item label="登录错误次数记录:">{{ruleForm.sign_error}}</el-descriptions-item>
        <el-descriptions-item label="账号状态:">
          <span v-if="ruleForm.status==0">正常</span>
          <span v-else-if="ruleForm.status==1">冻结</span>
          <span v-else-if="ruleForm.status==2">注销中</span>
          <span v-else-if="ruleForm.status==3">已注销</span>
        </el-descriptions-item>
        <el-descriptions-item label="地址">
          <el-tabs  v-model="active">
            <el-tab-pane v-for="(item,i) in ruleForm.address" :key="i" :label="'地址【'+(parseInt(i)+1)+'】'" :name="String((parseInt(i)+1))">
              <el-descriptions border>
                <el-descriptions-item label="联系人姓名:">{{item.name}}</el-descriptions-item>
                <el-descriptions-item label="性别:">
                  <span v-if="item.status==0">先生</span>
                  <span v-else>女士</span>
                </el-descriptions-item>
                <el-descriptions-item label="是否为默认地址:">
                  <span v-if="item.is_default==1">√</span>
                </el-descriptions-item>
                <el-descriptions-item label="联系人电话:">{{item.tel}}</el-descriptions-item>
                <el-descriptions-item label="小区名称:">{{item.estate_name}}</el-descriptions-item>
                <el-descriptions-item label="楼栋单元门牌号:">{{item.room_no}}</el-descriptions-item>
                <el-descriptions-item label="地址:">{{item.address}}</el-descriptions-item>
              </el-descriptions>
            </el-tab-pane>
          </el-tabs>
        </el-descriptions-item>

       </el-descriptions>
    </el-drawer>
    <el-drawer
        title="员工注册"
        v-model="empdrawer"
        size="30%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <el-form
          :model="empForm"
          :rules="rules"
          ref="ruleForm"
          label-width="110px"
          class="demo-ruleForm">
        <el-form
            :model="empForm"
            :rules="rules"
            ref="empForm"
            label-width="110px"
            class="demo-ruleForm">
          <el-form-item  label="头像" prop="">
            <el-upload
                style=" width: 100px;height: 100px"
                class="avatar-uploader"
                :action="UpURL"
                :show-file-list="false"
                :http-request="httpRequest"
                :before-upload="beforeAvatarUpload"
            >
              <img v-if="empForm.profile_url" :src="empForm.profile_url" class="avatar" />
              <i v-else  class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <p style="color: #999999"><span style="font-size: 16px" class="el-icon-info"> </span>图片尺寸 宽 200 X 高 200</p>
          </el-form-item>
          <el-form-item label="昵称" prop="">
            <el-input  v-model="empForm.nickname"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="mobile_phone">
            <el-input  v-model="empForm.mobile_phone"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input show-password v-model="empForm.password"></el-input>
          </el-form-item>
        </el-form>
        <el-form-item>
          <el-button type="primary" v-if="ruleForm.id" :loading="login" @click="okAdd('ruleForm',2)">修改</el-button>
          <el-button type="primary" v-else :loading="login" @click="okAdd('ruleForm',1)">注册</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>
<script>
// @ is an alias to /src
import * as axios from "@/api/memberApi"//本页面接口
import {postSkuData,getSkuData} from "@/api/skuApi"
import {getIMG, getUQIMGurl} from "@/api/common";
import md5 from "js-md5";
//本页面接口
export default {
  name: 'brand',
  data(){
    return{
      UpURL:'',
      login:false,
      drawer:false,
      empdrawer:false,

      referrer_member_id:'',//员工查询
      loading:false,
      higherArr:[],
      create_time:[],
      idcard_sex:'',
      sexArr:[
        {
          id:'男',
        },
        {
          id:'女',
        },
      ],

      tableData:[],
      active:'1',
      page:1,
      limit:10,
      total:0,
      keywords:'',
      ruleForm:{},
      empForm:{
        nickname:'',
        mobile_phone:'',
        profile_url:'',
        salt:'',
        password:'',
      },
      rules:{
        mobile_phone: [
          { required: true, message: '请填写手机', trigger: 'change' },
        ],
        password: [
          { required: true, message: '请填写密码', trigger: 'change' },
        ],
        nickname: [
          { required: true, message: '请填写名称', trigger: 'change' },
        ],
      }
    }
  },
  created() {
    this.onTable()
    this.keyupSubmit()
    this.onhigher()
  },

  methods:{
    onhigher(query){
      let form={
        page:1,
        limit:200,
        keywords:query
      }
      this.loading = true;
      getSkuData(form,'/api/admin/member/getEmp').then((res)=>{
        console.log(res)
        this.higherArr=res.data.data
        this.loading = false;
      }).catch((err)=>{
        this.loading = false;
      })
    },
    /*远程搜索*/


    //LOGO上传图片方法
    httpRequest(options) {
      this.login=true
      // console.log('options',options.file,options.file.type)
      return new Promise((resolve, reject) => {
        let src=options.file.type
        getUQIMGurl({bucket:'product',ext:'.'+src.substr(6)}).then((res)=>{
          // console.log('获取URL',res)
          if(res.code==0){
            this.UpURL=res.data.upload_url
            let url=res.data.preview_url
            // console.log(this.UpURL)
            getIMG(options.file,this.UpURL,options.file.type).then((res)=>{
              resolve(res)
              this.empForm.profile_url=url
              this.login=false
            }).catch((err)=>{
              reject(err)
              this.login=false
            })
          }else {
            this.$message.error('获取上传地址失败！！')
            this.login=false
          }
        })

      })
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'||
          file.type === "image/png" ||
          file.type === "image/jpg"||
          file.type === "image/gif";
      // const isLt2M = file.size / 1024 / 1024 < 4
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG、JPEG、PNG、 格式!')
      }
      // if (!isLt2M) {
      // this.$message.error('上传头像图片大小不能超过 4MB!')
      // }
      return isJPG
      // && isLt2M
    },
    /*图片上传*/
    //
    keyupSubmit(){
      document.onkeydown=e=>{
        let _key=window.event.keyCode;
        if(_key===13){
          this.search()
        }
      }
    },
    onTable(){
      let form={
        page:this.page,
        limit:this.limit,
        keywords:this.keywords,
        referrer_member_id:this.referrer_member_id,
        create_time:this.create_time,
        idcard_sex:this.idcard_sex,
      }
      postSkuData(form,'/api/admin/member').then((res)=>{
        console.log(res)
        this.tableData=res.data.data
        console.log('this.tableData',this.tableData)
        this.total=res.data.total
      }).catch((err)=>{

      })
    },
    search(){
      this.page=1
      this.onTable()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.limit=val
      this.onTable()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.page=val
      this.onTable()
    },
    add(){
      this.empForm={
        mobile_phone:'',
        salt:'',
        password:'',
        nickname:'',
        profile_url:'',
      }
      this.empdrawer=true
    },
    empFun(){
      console.log(md5('123456'))
    },
    okAdd(formName,val){
      if(this.login){
        return false
      }
      this.login=true
      this.empForm.salt=md5(randomString(30))
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(val==1){
            console.log('密码',this.empForm.password)
            this.empForm.password=md5(this.empForm.password+this.empForm.salt)
            console.log('密码加密',this.empForm.password)
            console.log('shuju',this.empForm)
            postSkuData(this.empForm,'/api/admin/member/empreg').then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.empdrawer=false
                  that.login=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }else {
            postSkuData(this.empForm,'/api/admin/member/grade/edit').then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                this.login=false
                setTimeout(function () {
                  that.empdrawer=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }
        } else {
          this.login=false
          console.log('error submit!!')
          return false
        }
      })
    },

    upData(row){
      axios.postediview({id:row.id}).then((res)=>{
        if(res.code==200){
          this.ruleForm=res.data
          this.drawer=true
        }else {
          this.$message.error(res.msg)
        }
      }).catch((err)=>{
        this.$message.error('服务器报错！！请联系管理员')
      })
    },


  }
}
function randomString(e) {
  e = e || 32;
  var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz123456789",
      a = t.length,
      n = "";
  for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
  return n
}
</script>
<style lang="less" >
.avatar-uploader .el-upload {
  border: 1px dashed #c1c1c1;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.el-upload{

}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-table .el-table__cell{
  padding: 0;
}
</style>